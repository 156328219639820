import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import React, { useState } from "react"
import tw, { styled } from "twin.macro"
import _ from "lodash"
import { getContrastText } from "../utils"
import { Popover } from "react-tiny-popover"
import useStats from "../hooks/useStats"
import CompetitionChip from "./CompetitionChip"

const GameContainer = styled.div`
  ${tw`flex flex-col w-full h-full relative p-1 overflow-y-auto `}
  box-shadow: 
    -1px 0 0 0 #999999, 
    0 -1px 0 0 #999999, 
    1px 0 0 0 #999999, 
    0 1px 0 0 #999999;
  background-color: ${props => props.backgroundColor};
  color: ${props => getContrastText(props.backgroundColor)};
  ${({ striped, backgroundColor, promoColor }) =>
    striped &&
    `background: repeating-linear-gradient(135deg, ${promoColor},${promoColor} 10px,${backgroundColor} 10px,${backgroundColor} 20px);`}
  scrollbar-color: #a0aec0;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0aec0;
    border-radius: 10px;
    border: 1px solid #a0aec0;
  }
`
const LogoContainer = styled.div`
  ${tw`w-10 h-8`}
  ${({ noTopMargin }) => (noTopMargin ? tw`mt-0 mr-1` : tw`mx-auto mt-2`)}
`
const GameTextLink = styled(Link)`
  ${tw`font-bold text-sm text-center hover:underline mx-auto text-ellipsis overflow-hidden whitespace-nowrap`}
  width: ${({ width }) => `${width}px`};
`
const GameText = styled.div`
  ${tw`font-bold text-sm text-center hover:underline mx-auto text-ellipsis overflow-hidden whitespace-nowrap`}
  width: ${({ width }) => `${width}px`};
`
const GameTime = styled.div`
  ${tw`text-center text-xs mt-1`}
`
const GameActionContainer = styled.div`
  ${tw`flex flex-col mt-auto mb-1 items-center`}
`
const GameLink = styled.a`
  ${tw`block relative text-center bg-black text-white mx-1 rounded py-0.5 font-bold text-xs uppercase`}
`

const PopOverContent = styled.div`
  ${tw`w-[240px] h-[240px] border border-black`}
`
const MediaContainer = styled.div`
  ${tw`text-sm mx-2`}
`

const DescriptionContainer = styled.div`
  ${tw`text-xs pb-1 overflow-y-auto`}
  scrollbar-width: thin;
  scrollbar-color: #cfcfcf;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
    border-radius: 10px;
    border: 1px solid #cfcfcf;
  }
`
const DescriptionHeader = styled.div`
  ${tw`flex text-xs font-bold items-center mt-2 ml-2`}
`
const DescriptionList = styled.ul`
  ${tw`py-0.5`}
`
const DescriptionItem = styled.li`
  ${tw`ml-7 text-[10]`}
`
const PopoverGameLink = styled.a`
  ${tw`flex justify-center bg-black text-white mx-1 rounded py-1 font-bold text-sm uppercase`}
`

const PopoverLogoContainer = styled.div`
  ${tw`w-14 h-12`}
  ${({ noTopMargin }) => (noTopMargin ? tw`mt-0 mr-2` : tw`mx-auto mt-2`)}
`
const PopoverGameTextLink = styled(Link)`
  ${tw`font-bold text-base text-center hover:underline`}
`
const PopoverGameText = styled.div`
  ${tw`font-bold text-base text-center text-ellipsis overflow-hidden`}
  width: ${({ width }) => `${width}px`};
`
const PopoverGameTime = styled.div`
  ${tw`text-center text-sm relative`}
`
const PopoverLocation = styled.div`
  ${tw`text-center text-xs`}
`
const CompleteGameContainer = styled.div`
  ${tw`flex flex-col mt-2 mx-1`}
`
const TeamContainer = styled.div`
  ${tw`flex flex-row items-center justify-center`}
`
const ScoreContainer = styled.div`
  ${tw`font-bold`}
`
const PopoverActionSvg = styled.img`
  ${tw`w-4 h-4 absolute top-1.5 left-6`}
`
const GameContainerLink = styled(Link)`
  ${tw`flex flex-col w-full h-full relative p-1 overflow-y-auto`}
  background-color: ${props => props.backgroundColor};
  color: ${props => getContrastText(props.backgroundColor)};
  ${({ striped, backgroundColor, promoColor }) =>
    striped &&
    `background: repeating-linear-gradient(135deg, ${promoColor},${promoColor} 10px,${backgroundColor} 10px,${backgroundColor} 20px);`}
  scrollbar-color: #a0aec0;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0aec0;
    border-radius: 10px;
    border: 1px solid #a0aec0;
  }
`

const ActionSvg = styled.img`
  ${tw`w-4 h-4 absolute left-6 top-1.5`}
`
const DateContainer = styled.div`
  ${tw`absolute left-1 top-1 text-sm`}
  color: ${props => props.color};
`

const CalendarGameItem = ({
  calendarItemData = [],
  color,
  displayDate,
  homeGameColor,
  awayGameColor,
  promoColor,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const getPosition = props => {
    const { childRect, parentRect } = props
    return {
      top: childRect.top - parentRect.top - 60,
      left: childRect.left - parentRect.left - 60,
    }
  }

  const allHomeGame = calendarItemData.every(gameData => gameData.isHomeGame)
  let backgroundColor = allHomeGame ? homeGameColor : awayGameColor

  const striped =
    calendarItemData.some(gameData => gameData.isHomeGame) &&
    calendarItemData.some(gameData => !gameData.isHomeGame)

  const allCompletedGame = calendarItemData.every(
    gameData => gameData.isCompletedGame
  )

  const completedGames = calendarItemData.filter(game => game.isCompletedGame)
  const scheduledGames = calendarItemData.filter(game => !game.isCompletedGame)
  const opponentTeam = _.get(calendarItemData, "0.opponentTeam")

  const onlyOneGame = calendarItemData.length === 1
  const promoDescriptions = _.get(
    calendarItemData,
    "0.game.node.frontmatter.promoDescriptions"
  )

  return (
    <Popover
      isOpen={isOpen}
      contentLocation={getPosition}
      reposition={true}
      content={
        <PopOverContent onMouseLeave={e => setIsOpen(false)}>
          <GameContainerWrapper
            backgroundColor={backgroundColor}
            striped={striped}
            // promoColor={promoColor}
            promoColor={homeGameColor}
            slug={onlyOneGame ? _.get(calendarItemData, "0.slug") : undefined}
            isCompletedGame={allCompletedGame}
            onlyOneGame={onlyOneGame}
          >
            {!_.isNil(promoDescriptions) && (
              <PopoverActionSvg src="/img/svg/asterisk.svg" alt="promo_game" />
            )}
            <DateContainer color={color}>{displayDate}</DateContainer>
            {completedGames.map((gameData, idx) => {
              const { homeTeamDoc, awayTeamDoc, score, league, slug } = gameData

              return (
                <>
                  <CompleteGameContainer key={idx}>
                    <TeamContainer>
                      <PopoverGameText width={100}>
                        {_.get(
                          awayTeamDoc,
                          "node.frontmatter.name",
                          "Team Name"
                        )}
                      </PopoverGameText>
                      <PopoverLogoContainer noTopMargin={true}>
                        <GatsbyImage
                          image={getImage(
                            _.get(awayTeamDoc, "node.frontmatter.logo", null)
                          )}
                          alt="awayteam"
                          style={{ width: "100%", height: "100%" }}
                          objectFit="contain"
                        />
                      </PopoverLogoContainer>
                      <ScoreContainer>{score.away}</ScoreContainer>
                    </TeamContainer>
                    <TeamContainer>
                      <PopoverGameText width={100}>
                        {_.get(
                          homeTeamDoc,
                          "node.frontmatter.name",
                          "Team Name"
                        )}
                      </PopoverGameText>
                      <PopoverLogoContainer noTopMargin={true}>
                        <GatsbyImage
                          image={getImage(
                            _.get(homeTeamDoc, "node.frontmatter.logo", null)
                          )}
                          alt="hometeam"
                          style={{ width: "100%", height: "100%" }}
                          objectFit="contain"
                        />
                      </PopoverLogoContainer>
                      <ScoreContainer>{score.home}</ScoreContainer>
                    </TeamContainer>
                  </CompleteGameContainer>
                  {!onlyOneGame && (
                    <GameActionContainer>
                      <CompetitionChip competition={league} to={slug} />
                    </GameActionContainer>
                  )}
                </>
              )
            })}
            {scheduledGames.length > 0 && (
              <>
                <PopoverLogoContainer>
                  <GatsbyImage
                    image={getImage(
                      _.get(opponentTeam, "node.frontmatter.logo", null)
                    )}
                    alt="opponent"
                    style={{ width: "100%", height: "100%" }}
                    objectFit="contain"
                  />
                </PopoverLogoContainer>
                {scheduledGames.map(gameData => {
                  const { isHomeGame, slug, opponentTeam } = gameData
                  return (
                    <PopoverGameTextLink to={slug} key={slug}>
                      {isHomeGame ? "vs. " : "@ "}{" "}
                      {_.get(
                        opponentTeam,
                        "node.frontmatter.name",
                        "Team Name"
                      )}
                    </PopoverGameTextLink>
                  )
                })}
                <PopoverGameTime>
                  {calendarItemData
                    .map(gameData => gameData.formattedTime)
                    .join(" & ")}
                </PopoverGameTime>
              </>
            )}
            {onlyOneGame && allCompletedGame && (
              <PopoverGameTime>
                {_.get(calendarItemData, "0.game.node.frontmatter.rainOut")
                  ? "RAIN OUT"
                  : "FINAL"}
              </PopoverGameTime>
            )}
            {onlyOneGame && (
              <PopoverLocation>
                {_.get(calendarItemData, "0.game.node.frontmatter.field")
                  ? _.get(calendarItemData, "0.game.node.frontmatter.field")
                  : `${_.get(
                      calendarItemData,
                      "0.homeTeamDoc.location.town",
                      ""
                    )} - ${_.get(
                      calendarItemData,
                      "0.homeTeamDoc.location.fieldName",
                      ""
                    )}`}
              </PopoverLocation>
            )}
            {onlyOneGame && !_.isNil(promoDescriptions) && (
              <DescriptionContainer>
                {!_.isEmpty(promoDescriptions) && (
                  <DescriptionHeader>Promotions</DescriptionHeader>
                )}
                <DescriptionList>
                  {(promoDescriptions || []).map((str, idx) => (
                    <DescriptionItem key={idx}>{str}</DescriptionItem>
                  ))}
                </DescriptionList>
              </DescriptionContainer>
            )}
            {(onlyOneGame || scheduledGames.length > 0) && (
              <GameActionContainer>
                <CompetitionChip
                  competition={_.get(calendarItemData, "0.league")}
                  to={
                    onlyOneGame ? _.get(calendarItemData, "0.slug") : undefined
                  }
                />
              </GameActionContainer>
            )}
          </GameContainerWrapper>
        </PopOverContent>
      }
    >
      <GameContainer
        backgroundColor={backgroundColor}
        promoColor={homeGameColor}
        striped={striped}
        onMouseEnter={e => setIsOpen(!isOpen)}
      >
        {!_.isNil(promoDescriptions) && (
          <ActionSvg src="/img/svg/asterisk.svg" alt="promo_game" />
        )}
        {completedGames.map((gameData, idx) => {
          const { homeTeamDoc, awayTeamDoc, score, league, slug } = gameData
          return (
            <>
              <CompleteGameContainer key={idx}>
                <TeamContainer>
                  <GameText width={70}>
                    {_.get(awayTeamDoc, "node.frontmatter.name", "Team Name")}
                  </GameText>
                  <LogoContainer noTopMargin={true}>
                    <GatsbyImage
                      image={getImage(
                        _.get(awayTeamDoc, "node.frontmatter.logo", null)
                      )}
                      alt="awayteam"
                      style={{ width: "100%", height: "100%" }}
                      objectFit="contain"
                    />
                  </LogoContainer>
                  <ScoreContainer>{score.away}</ScoreContainer>
                </TeamContainer>
                <TeamContainer>
                  <GameText width={70}>
                    {_.get(homeTeamDoc, "node.frontmatter.name", "Team Name")}
                  </GameText>
                  <LogoContainer noTopMargin={true}>
                    <GatsbyImage
                      image={getImage(
                        _.get(homeTeamDoc, "node.frontmatter.logo", null)
                      )}
                      alt="hometeam"
                      style={{ width: "100%", height: "100%" }}
                      objectFit="contain"
                    />
                  </LogoContainer>
                  <ScoreContainer>{score.home}</ScoreContainer>
                </TeamContainer>
              </CompleteGameContainer>
              {!onlyOneGame && (
                <GameActionContainer>
                  <CompetitionChip competition={league} to={slug} />
                </GameActionContainer>
              )}
            </>
          )
        })}
        {scheduledGames.length > 0 && (
          <>
            <LogoContainer>
              <GatsbyImage
                image={getImage(
                  _.get(opponentTeam, "node.frontmatter.logo", null)
                )}
                alt="opponent"
                style={{ width: "100%", height: "100%" }}
                objectFit="contain"
              />
            </LogoContainer>
            {scheduledGames.map(gameData => {
              const { isHomeGame, slug, opponentTeam } = gameData
              return (
                <GameTextLink to={slug} width={100} key={slug}>
                  {isHomeGame ? "vs. " : "@ "}{" "}
                  {_.get(opponentTeam, "node.frontmatter.name", "Team Name")}
                </GameTextLink>
              )
            })}
            <GameTime>
              {calendarItemData
                .map(gameData => gameData.formattedTime)
                .join(" & ")}
            </GameTime>
          </>
        )}
        {onlyOneGame && allCompletedGame && (
          <GameTime>
            {_.get(calendarItemData, "0.game.node.frontmatter.rainOut")
              ? "RAIN OUT"
              : "FINAL"}
          </GameTime>
        )}
        {(onlyOneGame || scheduledGames.length > 0) && (
          <GameActionContainer>
            <CompetitionChip
              competition={_.get(calendarItemData, "0.league")}
              to={onlyOneGame ? _.get(calendarItemData, "0.slug") : undefined}
            />
          </GameActionContainer>
        )}
      </GameContainer>
    </Popover>
  )
}

export default CalendarGameItem

const GameContainerWrapper = ({
  backgroundColor,
  striped,
  promoColor,
  isCompletedGame,
  onlyOneGame,
  slug,
  children,
}) => {
  if (isCompletedGame && onlyOneGame && slug) {
    return (
      <GameContainerLink
        to={slug}
        backgroundColor={backgroundColor}
        striped={striped}
        promoColor={promoColor}
      >
        {children}
      </GameContainerLink>
    )
  } else {
    return (
      <GameContainer
        backgroundColor={backgroundColor}
        striped={striped}
        promoColor={promoColor}
      >
        {children}
      </GameContainer>
    )
  }
}
